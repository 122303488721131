/*eslint-disable */

import request from "../../../Utils/curl"

let analyticsPayTransactions = {
  /**
   * payTransactions_list_counts
   */
  async payTransactions_list_counts (context, whereFilter = null) {
    try {
      let postData = new FormData()

      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      return await request.curl(context, "payTransactions_list_counts", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at payTransactions_list_counts() and Exception:", err.message)
    }
  }
}

export {
  analyticsPayTransactions
}
